import {
  CompanyBackendModel,
  Company,
  PartialCompany,
  ZuluCustomer,
  CompanyIdAndName,
  UploadCompanyLogoResponse,
} from './interfaces';
import { apiFetch, HTTPMethod } from '../fetch';
import {
  companyBackendModelToCompany,
  companyToCompanyBackendModel,
} from './companyTransformer';
import { ActiveStatus } from '..';
import { apiFetchFormData } from '../fetchFormData';

export const getCompaniesUserCanAccess = () =>
  apiFetch<PartialCompany[]>(`/companies?activeStatus=${ActiveStatus.All}`);

export const getCompany = (companyId: string) =>
  apiFetch<CompanyBackendModel>(`/companies/${companyId}`)
    .then(companyBackendModelToCompany);

export const putCompany = (company: Company) =>
  apiFetch<CompanyBackendModel>(
    `/companies/${company.id}`,
    companyToCompanyBackendModel(company),
    HTTPMethod.PUT
  ).then(companyBackendModelToCompany);

export const updateOrSaveCompanyLogo = ( uploadFile: any, companyId: string) => {
  const formData = new FormData();
  formData.append('uploadFile', uploadFile);
  return apiFetchFormData(
    `/companies/${companyId}/logo`,
    formData,
    HTTPMethod.PUT
  );
};

export const syncOnboardingStatus = () =>
  apiFetch('/companies/syncOnboardingStatus', undefined, HTTPMethod.PUT);

export const addPlusiusCustomer = (companyId: string) =>
  apiFetch<ZuluCustomer>(`/companies/${companyId}/zuluCustomer`, undefined, HTTPMethod.POST);

export const updatePlusiusCustomer = (companyId: string) =>
  apiFetch<ZuluCustomer>(`/companies/${companyId}/zuluCustomer`, undefined, HTTPMethod.PUT);

export const getCompanyIossNumberHistory = (companyId: string): Promise<string []> =>
  apiFetch(`/companies/${companyId}/iossNumberHistory`);

export const getCompaniesIdsAndNames = (): Promise<CompanyIdAndName []> =>
  apiFetch('/companies/companiesIdsAndNames');

export const getCompanyLogo = (companyId: string) =>
  apiFetch<UploadCompanyLogoResponse>(`/companies/${companyId}/logo`, undefined, HTTPMethod.GET)
    .then((response) =>
      ({ preview: response.content ? `data:${response.contentType};base64,${response.content}` : undefined }));