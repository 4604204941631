import { PartialTheme, useTheme as useFluentTheme } from '@fluentui/react';

export interface CbitTheme extends PartialTheme {
  breakpoints: {
    large?: number;
    medium: number;
    small: number;
    extraSmall: number;
  };
}

export const useTheme = () => useFluentTheme() as CbitTheme;
