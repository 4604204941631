import { CbitTheme } from './theme';

export const cbitDefaultTheme: CbitTheme = {
  breakpoints: {
    large: 1920,
    medium: 1440,
    small: 700,
    extraSmall: 480,
  },
};
