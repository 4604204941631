import { StatusColor } from 'components/shared/status';
import moment from 'moment';
import { HsCodeValidationStatus } from '../companyProducts';
import { DestinationGateway } from '../companySettings';

export interface DigitalCustomsOrder {
  id: string;
  digitalCustomsConsolidationId?: string;
  digitalCustomsImportId?: string;
  companyId: string;
  addedUtc: string;
  orderNumber: string;
  parcelReference: string;
  orderDate: string;
  trkNumber: string;
  importRule: string;
  value?: number;
  vatRate?: number;
  vatAmount?: number;
  dutyRate?: number;
  fee?: number;
  totalAmountCollected?: number;
  deliveryType: string;
  parcelWeight: number;
  productQuantity: number;
  customsStatus: CustomsStatus;
  customsStatusDescription: string;
  articlesInOrder: DigitalCustomsArticle[]
  updatedUtc: string;
  recipientName: string;
  recipientAddressLine1: string;
  recipientAddressLine2: string;
  recipientPhoneNumber: string;
  recipientMobilePhoneNumber: string;
  recipientCity: string;
  recipientState: string;
  recipientPostalCode: string;
  recipientEmailAddress: string;
  recipientCountryCode: string;
  senderName: string;
  senderAddressLine1: string;
  senderAddressLine2?: string;
  senderCity: string;
  senderState?: string;
  senderPostalCode: string;
  senderCountryCode: string;
  senderPhone?: string;
  senderEmail?: string;
}

export enum CustomsStatus {
  Unprocessed = 'Unprocessed',
  Error = 'Error',
  ReadyToSend = 'ReadyToSend',
  SentToBroker = 'SentToBroker',
  Pending = 'Pending',
  OnHold = 'OnHold',
  Initiated = 'Initiated',
  Received = 'Received',
  CustomsError = 'CustomsError',
  Ready = 'Ready',
  NoStatus = '',
}

export const CustomsStatusColor = {
  [CustomsStatus.Unprocessed]: StatusColor.NoStatus,
  [CustomsStatus.ReadyToSend]: StatusColor.Processing,
  [CustomsStatus.SentToBroker]: StatusColor.Processing,
  [CustomsStatus.Initiated]: StatusColor.Processing,
  [CustomsStatus.Pending]: StatusColor.Processing,
  [CustomsStatus.Received]: StatusColor.Processing,
  [CustomsStatus.Ready]: StatusColor.Success,
  [CustomsStatus.OnHold]: StatusColor.Warning,
  [CustomsStatus.Error]: StatusColor.Warning,
  [CustomsStatus.CustomsError]: StatusColor.Warning,
  [CustomsStatus.NoStatus]: StatusColor.Warning,
};

export interface DigitalCustomsArticle {
  id: string;
  digitalCustomsOrderId?: string;
  companyId: string;
  addedUtc: string;
  articlePrice: number;
  productTypeDescription: string;
  singleUnitValueOfProduct?: number;
  productItemOrigin: string;
  hsCode: string;
  productUnitWeight?: number;
  detailedProductDescription: string;
  composition: string;
  productCode: string;
  productUrl: string;
  hsCodeValidationStatus?: HsCodeValidationStatus;
  hsCodeValidationStatusUtcDate: string;
  updatedUtc?: string;
  lrn?: string;
  customsStatus?: string;
  customsId?: string;
  productQuantity?: number;
  hsCodeCorrectionId?: string;
}

export interface DigitalCustomsArticleAndConsolidation {
  article: DigitalCustomsArticle,
  consolidation: DigitalCustomsConsolidation
}

export interface DigitalCustomsConsolidationError {
  message: string;
  addedUtc: string;
}

export interface DigitalCustomsConsolidation {
  id: string;
  digitalCustomsImportId?: string;
  companyId: string;
  addedUtc: string;
  consolidationReference: string;
  consolidationBusinessFlow: string;
  parcelCount?: number;
  consolidationWeight?: number;
  serviceCode: string;
  invalidHsCodesInConsolidation: number;
  successFullySentToMymoUtc?: string;
  errors: DigitalCustomsConsolidationError[],
  customsOfficeOfArrival: string;
  customsOfficeOfDeparture: string;
  customsOfficeOfExitOrEntry: string;
  estimatedTimeOfArrival: string;
  isTest: boolean;
  masterWaybillNumber: string;
  modeOfTransportAtBorder: number;
  modeOfTransportInland: number;
  portOfDestination: string;
  portOfLoading: string;
  transportMeansAtBorder: string;
  transportMeansDepartureOrArrival: string;
  transportMeansNationalityAtBorder: string;
  transportMeansNationalityDepartureOrArrival: string;
  ordersInConsolidation: DigitalCustomsOrder[]
  notes: string
}

export interface DigitalCustomsMaster {
  id: string;
  masterWaybillNumber: string;
  uploadedUtc: string;
  portOfDestination: string;
  portOfLoading: string;
  estimatedTimeOfArrival?: string;
  unprocessedCount: number;
  waitingForResponseCount: number;
  heldByCustomsCount: number;
  importCompletedCount: number;
  numberOfBoxes: number;
  numberOfParcels: number;
  totalWeight: number;
  transportMeansAtBorder: MeansOfTransportation;
  notes: string;
  importClearedAt: string;
  isPreAlert: boolean;
  gateway?: DestinationGateway;
  customsOfficeOfArrival: string;
  customsOfficeOfDeparture: string;
}

export interface DigitalCustomsMasterPreview {
  id: string;
  masterWaybillNumber: string;
  estimatedTimeOfArrival?: string;
  numberOfParcels: number;
  uploadedUtc: string;
  isPreAlert: boolean;
  unprocessedCount: number;
  waitingForResponseCount: number;
  heldByCustomsCount: number;
  importCompletedCount: number;
  portOfDestination: string;
  portOfLoading: string;
  gateway?: DestinationGateway;
}

export enum DigitalCustomsTableType {
  DigitalCustoms,
  CompanyDigitalCustoms,
}

export const initialDateRangeValuesForDigitalCustomsOrders = {
  from: moment().utc().add(-1, 'days').startOf('day').toJSON(),
  to: moment().utc().add(1, 'days').endOf('day').toJSON(),
};

export enum DigitalCustomsPageStatus {
  Loading,
  Idle,
  Error,
  ViewArticleItems,
  LoadingArticleItems,
  ArticleItemsError,
  LoadingCompany,
}

export interface MasterWaybillDocument {
  documentId?: string;
  uploadedFileIds?: string[];
  failedToUpload?: boolean;
  addedDate?: string;
}
export interface DigitalCustomsConsolidationDocument {
  id: string;
  digitalCustomsConsolidationId: string;
  companyId: string;
  uploadedFiles: any[];
}
export interface HSCodeCorrection {
  id: string;
  companyId: string;
  productDescription: string;
  oldHSCode: string;
  mappedHSCode: string;
  addedUtc: string;
  revokedUtc?: string;
}

export interface DigitalCustomsConsolidationsResendResult {
  message: string,
  successfulConsolidationRefs: string[],
  failedConsolidationRefs: string[]
}
export enum MeansOfTransportation {
  TransportModeNotSpecified,
  MaritimeTransport,
  RailTransport,
  RoadTransport,
  AirTransport,
  PostalConsignment,
  RollOnRollOff,
  FixedTransportInstallations,
  InlandWaterwayTransport,
  ModeUnknown,
}

export interface DigitalCustomsLinehaul {
  portOfDestination: string | null;
  portOfLoading: string | null;
  gateway?: DestinationGateway;
}