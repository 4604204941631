import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styles';

export const enum ScreenSize{
  Large,
  Medium,
  Small,
  ExtraSmall,
}

export const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>(ScreenSize.Medium);
  const theme = useTheme();

  const getScreenSize = useCallback(() => {
    if (window.innerWidth > theme.breakpoints.medium ) {
      setScreenSize(ScreenSize.Large);
    }
    else if (window.innerWidth > theme.breakpoints.small && window.innerWidth <= theme.breakpoints.medium) {
      setScreenSize(ScreenSize.Medium);
    }
    else if (window.innerWidth > theme.breakpoints.extraSmall && window.innerWidth <= theme.breakpoints.small) {
      setScreenSize(ScreenSize.Small);
    }
    else if (window.innerWidth <= theme.breakpoints.extraSmall) {
      setScreenSize(ScreenSize.ExtraSmall);
    }
    else { return; }
  }, [theme]);

  useEffect(() => {
    getScreenSize();
    window.addEventListener('resize', getScreenSize);

    return () => {
      window.removeEventListener('resize', getScreenSize);
    };
  }, [getScreenSize]);

  return {screenSize};

};