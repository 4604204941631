import { Suspense, lazy } from 'react';
import { Route, Redirect, Switch, BrowserRouter } from 'react-router-dom';
import { initializeIcons } from '@uifabric/icons';
import { ThemeProvider } from '@fluentui/react';
import { AppDataLoader } from './AppDataLoader';
import { Header } from 'components/header';
import { ApiAuthorizationRoutes } from 'components/apiAuthorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from 'integrations/crossborderit';
import { AuthorizeRoute } from 'components/apiAuthorization/AuthorizeRoute';
import { NotFound } from 'components/notFound';
import 'antd/dist/antd.css';
import 'styles/index.css';
import { Provider as StoreProvider } from 'react-redux';
import { setupStore } from 'store';
import { setupInternationalization } from 'i18n';
import { useTranslation } from 'react-i18next';
import { Layout } from 'components/shared/layout';
import {
  MerchantOnboarding,
  MerchantOnboardingSuccess,
} from 'components/merchantOnboardning';
import { cbitDefaultTheme, initializeCustomIcons } from 'styles';
import { SupportedBrowsersGuard } from './SupportedBrowsersGuard';
import {
  StartOnboardingPage,
} from 'components/onboarding/startOnboarding';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
initializeFileTypeIcons();

const CbitAdminModule = lazy(() => import('components/cbitAdmin'));
const OnboardingModule = lazy(() => import('components/onboarding'));
const WorkspaceModule = lazy(() => import('components/workspace'));

initializeIcons();
initializeCustomIcons();
setupInternationalization();
const store = setupStore();
const baseUrl: string
  = document.getElementsByTagName('base')[0].getAttribute('href') || '';

export const App = () => {
  const { t } = useTranslation();

  return (
    <SupportedBrowsersGuard>
      <StoreProvider store={store}>
        <ThemeProvider theme={cbitDefaultTheme} style={{ height: '100%' }}>
          <BrowserRouter basename={baseUrl}>
            <Switch>
              <Redirect exact from="/" to="/workspace" />
              <Route exact path="/404" component={NotFound} />
              <Route
                exact
                path="/merchant-onboarding"
                component={MerchantOnboarding}
              />
              <Route
                exact
                path="/merchant-onboarding-success"
                component={MerchantOnboardingSuccess}
              />
              <Route
                exact
                path="/start-onboarding"
                component={StartOnboardingPage}
              />
              <Route path={[ApplicationPaths.ApiAuthorizationPrefix]}>
                <Layout>
                  <Layout.Header>
                    <Header />
                  </Layout.Header>

                  <Switch>
                    <Route
                      path={ApplicationPaths.ApiAuthorizationPrefix}
                      component={ApiAuthorizationRoutes}
                    />
                  </Switch>
                </Layout>
              </Route>

              <AuthorizeRoute
                path="/"
                component={() => (
                  <AppDataLoader>
                    <Suspense
                      fallback={
                        <Layout style={{ height: '100%' }}>
                          <Layout.Header>
                            <Header />
                          </Layout.Header>

                          <Layout.Loading
                            loadingLabel={t('Fetching app content...')}
                          />
                        </Layout>
                      }
                    >
                      <Switch>
                        <Route path="/admin" component={CbitAdminModule} />
                        <Route path="/onboarding" component={OnboardingModule} />
                        <Route path="/workspace" component={WorkspaceModule} />
                      </Switch>
                    </Suspense>
                  </AppDataLoader>
                )}
              />

              <Redirect to="/404" />
            </Switch>
          </BrowserRouter>
        </ThemeProvider>
      </StoreProvider>
    </SupportedBrowsersGuard>
  );
};
